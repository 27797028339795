<template>
<div>
  <h1 class="title grey--text">编辑价格</h1>
  <div class="subheader">{{programInfo.title}}</div>
  <v-card flat class="my-5">
    <v-layout class="flex-column align-center">
      <v-flex v-for="(packageForPurchase, index) in programInfo.packagesForPurchase" v-bind:key="index" style="width:550px;">
        <y-program-package-for-purchase
          :packageForPurchase="packageForPurchase"
          :programPackageForPurchaseCategories="programPackageForPurchaseCategories"
          v-on:upsert="programPackageForPurchaseUpsert($event, index)"
          v-on:program-package-deleted="programInfo.packagesForPurchase.splice(index, 1)"
        />
      </v-flex>
    </v-layout>
    <v-fab-transition>
      <v-dialog
          v-model="dialogAddPackage"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color='primary'
              fixed
              bottom
              right
            >
              <v-icon
                left
                dark
              >
                add
              </v-icon>
              新增
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              新增购买选项
            </v-card-title>

            <v-card-text>
              <v-layout row wrap>
                <v-form
                  ref="programForm"
                  v-model="ispackageForPurchaseNewFormVisiblyValid"
                  lazy-validation
                  class="fullwidth"
                  style="width:650px;"
                >
                  <v-flex xs12>
                    <v-text-field
                      label="名称"
                      :rules="rules.purchaseTitleRules"
                      v-model="packageForPurchaseNew.title"
                      outline
                    ></v-text-field>
                    <v-text-field
                      label="描述"
                      :rules="rules.purchaseDescriptionRules"
                      v-model="packageForPurchaseNew.description"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="px-2">
                    <v-text-field
                      label="所含课时总数"
                      :rules="rules.purchaseQuantityRules"
                      v-model="packageForPurchaseNew.quantity"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs5 class="px-2">
                  <v-text-field
                    label="价格(元)"
                    :rules="rules.purchasePriceRules"
                    v-model="priceYuan"
                    outline
                  ></v-text-field>
                  </v-flex>
                  <v-select
                    v-model="packageForPurchaseNew.categoryCode"
                    :items="programPackageForPurchaseCategories"
                    item-text="category"
                    item-value="code"
                    label="选择类型"
                    single-line
                    :rules="rules.purchaseCategoryCodeRules"
                  ></v-select>
                </v-form>
              </v-layout>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                @click="dialogAddPackage = false"
              >
                取消
              </v-btn>
              <v-btn
                :disabled="!ispackageForPurchaseNewFormVisiblyValid"
                color="primary"
                @click="programPackageForPurchaseInsert"
              >
                新增
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-fab-transition>
  </v-card>
  <!-- <div v-for="(purchasePackage, index) in purchasePackageInfo" :key="`${index}-purchase`">
    <v-layout row wrap>
      <v-flex xs12 sm6 md4>
        <v-text-field
          label="名称"
          :rules="rules.purchaseTitleRules"
          v-model="purchasePackage.title"
          outline
        ></v-text-field>
        <v-text-field
          label="描述"
          :rules="rules.purchaseDescriptionRules"
          v-model="purchasePackage.description"
          outline
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-text-field
          label="所含课时总数"
          :rules="rules.purchaseQuantityRules"
          v-model="purchasePackage.quantity"
          outline
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md4>
      <v-text-field
        label="价格(元)"
        :rules="rules.purchasePriceRules"
        @blur="yuanToFen($event, index)"
        outline
      ></v-text-field>
      </v-flex>
      <v-select
        v-model="purchasePackage.category_code"
        :items="categories"
        item-text="category"
        item-value="code"
        label="选择类型"
        single-line
        :rules="[rules.selectionRequired]"
      ></v-select>
      <v-icon v-if="purchasePackage.status_code"
        @click="purchasePackage.status_code = 0">
        visibility
      </v-icon>
      <v-icon v-if="!purchasePackage.status_code"
        @click="purchasePackage.status_code = 1">
        visibility_off
      </v-icon>
      <v-icon @click="splicePurchasePackageInfo(index)">delete</v-icon>
    </v-layout>
  </div>
  <v-btn
    medium
    @click="expandPurchasePackageInfo">
    + 增加购买选项
  </v-btn>
  <v-divider></v-divider> -->
  <!-- <v-subheader inset>承接方补偿</v-subheader>
  <div v-for="(venueCompensation, index) in venueCompensationInfo" :key="`${index}-compensation`">
    <v-layout row wrap>
      <v-flex xs12 sm6 md4>
        <v-text-field
          label="描述"
          :rules="venueDescriptionRules"
          v-model="venueCompensation.description"
          outline
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-text-field
          label="合约承接课时数"
          :rules="venueQuantityRules"
          v-model="venueCompensation.quantity"
          outline
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-text-field
          label="递增比例"
          :rules="[() => true]"
          v-model="venueCompensation.percentage"
          outline
        ></v-text-field>
      </v-flex>
      <v-icon v-if="venueCompensation.status_code"
        @click="venueCompensation.status_code = 0">
        visibility
      </v-icon>
      <v-icon v-if="!venueCompensation.status_code"
        @click="venueCompensation.status_code = 1">
        visibility_off
      </v-icon>
      <v-icon @click="spliceVenueCompensationInfo(index)">delete</v-icon>
    </v-layout>
  </div>
  <v-btn
    medium
    @click="expandVenueCompensationInfo">
    + 增加承接选项
  </v-btn> -->
  <!-- <v-divider></v-divider>
  <v-subheader inset>班级</v-subheader>
  <div v-for="(singleClass, index) in classInfo" :key="`${index}-class`">
    <v-layout row wrap>
      <v-flex xs12 sm6 md4>
        <v-text-field
          label="班级名称"
          :rules="classNameRules"
          v-model="singleClass.name"
          outline
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-text-field
          label="班级描述"
          :rules="classDescriptionRules"
          v-model="singleClass.description"
          outline
        ></v-text-field>
      </v-flex>
      <v-icon v-if="singleClass.status_code"
        @click="singleClass.status_code = 0">
        visibility
      </v-icon>
      <v-icon v-if="!singleClass.status_code"
        @click="singleClass.status_code = 1">
        visibility_off
      </v-icon>
      <v-icon @click="spliceClassInfo(index)">delete</v-icon>
    </v-layout>
  </div>
  <v-btn
    medium
    @click="expandClassInfo">
    + 新建班级
  </v-btn>
  <span class="error" v-if="error">
    {{error}}
  </span>
  <div></div> -->
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import {
} from 'lodash'
// import Vue from 'vue'
import ProgramService from '@/services/ProgramService'
import YProgramPackageForPurchase from '../components/YProgramPackageForPurchase'
export default {
  components: {
    YProgramPackageForPurchase
  },
  watch: {
  },
  computed: {
    priceYuan: {
      set: function (priceYuan) {
        this.packageForPurchaseNew.priceFen = priceYuan * 100
      },
      get: function () {
        return this.packageForPurchaseNew.priceFen ? this.packageForPurchaseNew.priceFen / 100 : null
      }
    }
  },
  created: function () {
  },
  data () {
    return {
      programInfo: {
        programId: null,
        organizationId: null,
        tutorId: null,
        title: '',
        description: '',
        imgUrl: '',
        videoFileStorageId: null,
        imageFileStorageId: null,
        publishProgramCommonPropertyId: null,
        editProgramCommonPropertyId: null,
        programCommonPropertyId: null,
        moduleLengthMin: 45,
        sessionModuleQuantity: 2,
        maxStudentNumberPerSession: 5,
        trainingObjective: '',
        trainingApproach: '',
        targetStudent: '',
        prerequisite: '',
        entryStudentAgeMonthLowerLimit: 36,
        entryStudentAgeMonthUpperLimit: 72,
        totalModuleQuantity: 20,
        interimAdmission: true,
        descriptionDetail: '',
        statusCode: null,
        categoryCode: null,
        prerequisiteTags: [],
        targetStudentTags: [],
        objectiveTags: [],
        tags: [],
        packagesForPurchase: []
      },
      programPackageForPurchaseCategories: [],
      rules: {
        // general
        required: v => !!v || '必填',
        selectionRequired: v => (v.length !== 0) || '必填',
        objectSelectionRequired: v => !!v || '必填',
        // purchase
        purchaseTitleRules: [
          v => this.rules.required(v)
        ],
        purchaseDescriptionRules: [
          v => this.rules.required(v)
        ],
        purchaseQuantityRules: [
          v => this.rules.required(v),
          v => v * 1 === parseInt(v * 1, 10) || '须为整数'
        ],
        purchasePriceRules: [
          v => this.rules.required(v),
          v => v * 100 === parseInt(v * 100, 10) || '请输入正确格式'
        ],
        purchaseCategoryCodeRules: [
          v => this.rules.required(v)
        ],
        venueDescriptionRules: [
          v => this.rules.required(v)
        ],
        venueQuantityRules: [
          v => this.rules.required(v)
        ],
        venueStatusCodeRules: [
          v => this.rules.required(v)
        ],
        venuePercentageRules: [
          v => this.rules.required(v)
        ],
        classNameRules: [
          v => this.rules.required(v)
        ],
        classDescriptionRules: [
          v => this.rules.required(v)
        ]
      },
      dialogAddPackage: false,
      packageForPurchaseNew: {
        title: '',
        description: '',
        quantity: null,
        priceFen: null,
        categoryCode: null
      },
      ispackageForPurchaseNewFormVisiblyValid: false
    }
  },
  async beforeMount () {
    this.$store.dispatch('setQueryingState', true)
    this.programInfo = this.$store.state.route.params.programInfo
    this.programPackageForPurchaseCategories = await this.getProgramPackageForPurchaseCategories()
    this.$store.dispatch('setQueryingState', false)
  },
  async mounted () {
  },
  updated: async function () {
  },
  methods: {
    async getTutorsByOrganizationId (organizationId) {
      try {
        console.log('\n#getTutorsByOrganizationId start ... \norganizationId:', organizationId)
        const res = await ProgramService.getTutors({ organizationId })
        console.log('Tutor info received! res.data:', res.data)
        return res.data
      } catch (err) {
        return err.response.data
      }
    },
    async getProgramPackageForPurchaseCategories () {
      try {
        const res = await ProgramService.getProgramPackageForPurchaseCategories()
        console.log('getProgramPackageForPurchaseCategories received! response.data:', res.data)
        return res.data
      } catch (err) {
        return err.res.data
      }
    },
    programPackageForPurchaseUpsert (e, index) {
      console.log('programPackageForPurchaseUpsert: e:')
      console.log(e)
      this.programInfo.packagesForPurchase.splice(index, 1, e)
    },
    async programPackageForPurchaseInsert () {
      console.log('Insert package: ')
      const res = await ProgramService.programPackageForPurchaseUpsert({
        programPackageForPurchase: {
          quantity: this.packageForPurchaseNew.quantity,
          categoryCode: this.packageForPurchaseNew.categoryCode,
          description: this.packageForPurchaseNew.description,
          priceFen: this.packageForPurchaseNew.priceFen,
          programId: this.programInfo.id,
          title: this.packageForPurchaseNew.title,
          visibleToPublic: 1
        }
      })
      console.log(res)
      this.programInfo.packagesForPurchase.push(res.data.data[0])
      this.dialogAddPackage = false
      this.packageForPurchaseNew = {
        title: '',
        description: '',
        quantity: null,
        priceFen: null,
        categoryCode: null
      }
    }
    // methods that are not used in this view
    // async submit (programInfo, purchasePackageInfo, venueCompensationInfo, classInfo) {
    //   if (this.$refs.programForm.validate() && programInfo.tutor_id) { // the form element is marked as ref="form" and can be refered here
    //     this.showWait = true
    //     try {
    //       const response = await this.createProgram(programInfo, purchasePackageInfo, venueCompensationInfo, classInfo)
    //       this.showWait = false
    //       if (response.programId) {
    //         this.error = null
    //         this.$router.push({ name: 'programs' })
    //       } else {
    //         console.log('something went wrong in creating program')
    //       }
    //     } catch (error) {
    //       this.error = error.response.data.msg
    //       this.showWait = false
    //     }
    //   }
    // },
    // async createProgram (programInfo, purchasePackageInfo, venueCompensationInfo, classInfo) {
    //   try {
    //     const response = await ProgramService.createProgram({ programInfo: programInfo, purchasePackageInfo: purchasePackageInfo, venueCompensationInfo: venueCompensationInfo, classInfo: classInfo })
    //     console.log('create program finished! response.data:', response.data)
    //     return response.data
    //   } catch (err) {
    //     return err.response.data
    //   }
    // },
    // expandClassInfo () {
    //   this.classInfo.push({
    //     name: '',
    //     description: '',
    //     tutor_id: this.programInfo.tutor_id,
    //     status_code: 1
    //   })
    // },
    // spliceClassInfo (index) {
    //   this.classInfo.splice(index, 1)
    // },
    // expandVenueCompensationInfo () {
    //   this.venueCompensationInfo.push({
    //     description: '',
    //     quantity: null,
    //     percentage: '5',
    //     status_code: 1,
    //     freezing_fund_fen: this.venue_freezing_fund_fen
    //   })
    // },
    // spliceVenueCompensationInfo (index) {
    //   this.venueCompensationInfo.splice(index, 1)
    // },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
