<template>
<div class="program-card">
  <v-layout align-center justify-center row wrap>
    <v-card style="width:550px;" :class="`ma-5 ${packageForPurchase.visibleToPublic ? 'visible' : 'invisible grey lighten-3'}`">
      <div
        class="stack-top repeating-gradient">
      </div>
      <v-card-title primary-title>
        <div>{{packageForPurchase.title}}</div>
      </v-card-title>
      <v-card-text>
        <v-row align="center" class="mx-0">
        </v-row>
        <div class="my-4 text-subtitle-1 primary--text">￥{{packageForPurchase.priceFen / 100}}<span> | 包含{{packageForPurchase.quantity}}课时</span><span><v-chip small outlined class="mx-2" :color="`${packageForPurchase.categoryCode == 1 ? 'primary' : 'blue'}`">{{ packageForPurchase.category }}</v-chip></span></div>
        <div>{{packageForPurchase.description}}</div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <!-- 便捷操作按钮 -->
      <v-card-actions>
        <span class="grey--text ms-4">
          <span v-if="packageForPurchase.purchaseNumber > 0"><v-icon small>lock</v-icon>锁定编辑，可隐藏 (付费: {{packageForPurchase.purchaseNumber}})</span>
          <span v-else><v-icon small>lock_open</v-icon></span>
        </span>
        <v-spacer></v-spacer>
        <!-- 编辑 [program_common_property: DRAFT] -->
        <v-dialog
          v-model="dialogEditPackage"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text
              v-bind="attrs"
              v-on="on"
              v-if="packageForPurchase.purchaseNumber === 0"
            >
              <v-icon >edit</v-icon>编辑
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              编辑购买选项
            </v-card-title>
            <v-form
              ref=""
              v-model="isFormVisiblyValid"
              lazy-validation
              style=""
            >
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="名称"
                      :rules="rules.purchaseTitleRules"
                      v-model="packageForPurchaseModify.title"
                      outline
                    ></v-text-field>
                    <v-text-field
                      label="描述"
                      :rules="rules.purchaseDescriptionRules"
                      v-model="packageForPurchaseModify.description"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 class="px-2">
                    <v-text-field
                      label="所含课时总数"
                      :rules="rules.purchaseQuantityRules"
                      v-model="packageForPurchaseModify.quantity"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 class="px-2">
                  <v-text-field
                    label="价格(元)"
                    :rules="rules.purchasePriceRules"
                    v-model="priceYuan"
                    outline
                  ></v-text-field>
                  </v-flex>
                  <v-select
                    v-model="packageForPurchaseModify.categoryCode"
                    :items="programPackageForPurchaseCategories"
                    item-text="category"
                    item-value="code"
                    label="选择类型"
                    single-line
                    :rules="[rules.selectionRequired]"
                  ></v-select>
                </v-layout>
              </v-card-text>
            </v-form>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                @click="dialogEditPackage = false"
              >
                取消
              </v-btn>
              <v-btn
                color="primary"
                @click="programPackageForPurchaseUpsert"
                :disabled='!isFormVisiblyValid'
              >
                提交
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- 隐藏  [program_common_property: APPROVED && program: PUBLISHED] -->
        <v-btn
          text
          :class="`${packageForPurchase.visibleToPublic? 'green--text': 'red--text'}`"
          @click="toggleVisibility"
        >
          <v-icon>{{ packageForPurchase.visibleToPublic? 'visibility_off': 'visibility' }}</v-icon> {{ packageForPurchase.visibleToPublic? '隐藏': '显示' }}
        </v-btn>

        <!-- 删除 [program_common_property: DRAFT || program: CLOSED] -->
        <v-dialog
          v-model="dialogDeletePackage"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text
              v-bind="attrs"
              v-on="on"
              v-if="packageForPurchase.purchaseNumber === 0"
            >
              <v-icon color="grey">delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              删除购买选项
            </v-card-title>

            <v-card-text>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                @click="dialogDeletePackage = false"
              >
                取消
              </v-btn>
              <v-btn
                color="error"
                @click="deleteProgramPackageForPurchase"
              >
                确定
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-layout>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import helper from '../lib/helper'
import {
  merge as _merge
  // assign as _assign
} from 'lodash'
export default {
  components: {
  },
  props: { // make it possible to pass in parameter <panel title="课程模版">
    packageForPurchase: {
      type: Object,
      default: null
    },
    programPackageForPurchaseCategories: {
      type: Array,
      default: () => [
        {
          code: 1,
          category: '固定进度'
        },
        {
          code: 2,
          category: '灵活出席'
        }
      ]
    }
  },
  watch: {
  },
  data () {
    return {
      dialogDeletePackage: false,
      dialogEditPackage: false,
      rules: {
        // general
        required: v => !!v || '必填',
        selectionRequired: v => (v.length !== 0) || '必填',
        objectSelectionRequired: v => !!v || '必填',
        // purchase
        purchaseTitleRules: [
          v => this.rules.required(v)
        ],
        purchaseDescriptionRules: [
          v => this.rules.required(v)
        ],
        purchaseQuantityRules: [
          v => this.rules.required(v)
        ],
        purchasePriceRules: [
          v => this.rules.required(v)
        ],
        purchaseStatusCodeRules: [
          v => this.rules.required(v)
        ]
      },
      packageForPurchaseModify: {},
      isFormVisiblyValid: true
    }
  },
  computed: {
    priceYuan: {
      set: function (priceYuan) {
        this.packageForPurchaseModify.priceFen = priceYuan * 100
      },
      get: function () {
        return this.packageForPurchaseModify.priceFen / 100
      }
    }
  },
  created: function () {
    _merge(this.packageForPurchaseModify, this.packageForPurchase)
  },
  methods: {
    async programPackageForPurchaseUpsert () {
      console.log('upsert package: ')
      console.log(this.packageForPurchase)
      const res = await ProgramService.programPackageForPurchaseUpsert({
        programPackageForPurchase: {
          id: this.packageForPurchaseModify.id,
          quantity: this.packageForPurchaseModify.quantity,
          categoryCode: this.packageForPurchaseModify.categoryCode,
          description: this.packageForPurchaseModify.description,
          priceFen: this.packageForPurchaseModify.priceFen,
          programId: this.packageForPurchaseModify.programId,
          title: this.packageForPurchaseModify.title,
          visibleToPublic: this.packageForPurchaseModify.visibleToPublic
        }
      })
      this.dialogEditPackage = false
      this.$emit('upsert', helper.camelize(res.data.data[0]))
    },
    async deleteProgramPackageForPurchase () {
      const res = await ProgramService.deleteProgramPackagesForPurchase({ programPackagesForPurchaseIds: [this.packageForPurchaseModify.id] })
      if (res.data.data.deletedProgramPackageForPurchaseIds.length > 0) {
        this.$emit('program-package-deleted', res.data.data)
      }
      this.dialogDeletePackage = false
    },
    async toggleVisibility () {
      this.packageForPurchaseModify.visibleToPublic = !this.packageForPurchaseModify.visibleToPublic
      const res = await ProgramService.programPackageForPurchaseUpsert({
        programPackageForPurchase: {
          id: this.packageForPurchaseModify.id,
          visibleToPublic: this.packageForPurchaseModify.visibleToPublic
        }
      })
      this.$emit('upsert', helper.camelize(res.data.data[0]))
    },
    yuanToFen ($event, index) {
      this.packageForPurchase.priceFen = $event.currentTarget.value * 100
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .visible {
    border-left: 4px solid #3cd1c3;
  }
  .invisible {
    border-left: 4px solid grey;
  }
</style>
